

.section-slider {
    display: flex;
    overflow-x: auto;
    padding-left: 1rem;
    margin-top: 3rem;
}

.section-slider::-webkit-scrollbar {
    display: none;
} /* to deactivate the scrollbar*/

.contact-form {
    margin: var(--element-margin) 1rem 0;
    display: flex;
}
