.footer {
    background-color: var(--gray-dark);
    color: white;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-section {
    margin-bottom: var(--element-margin);
}

.footer-title {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 0.5rem;
}

.footer-list p {
    color: var(--gray-light);
    text-align: center;
    margin: 0.25rem auto;
}

/* STORE LOGOS */

.store-logo {
    display: block;
    margin: 0 auto;
    height: 80px;
}

/* SOCIAL LOGOS */

.social-logos {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.social-logo {
    height: 40px;
}

/* COPYRIGHT */

.footer-copyright div {
    color: black;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
    font-size: 1rem;
    padding: 0;
}


@media (min-width: 768px) {
    .footer-main {
        flex-direction: row-reverse;
        gap: 4rem;
        align-items: flex-start;
    }
}