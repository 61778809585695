.checkbox-acceptance {
    display: flex;
    align-items: center;
}

label {
    margin-left: 6px;
}

input[type="checkbox"] {
    width:1.5rem;
    height: 1.3rem;
}

.soon-page .contactform {
    padding-left: 0;
    padding-right: 0;

}

.subscription-form .success-message {
    margin-top: 2rem;
}

@media (min-width: 768px) {
    .soon-page .page-header {
        flex-direction: column;
    }
}

