/* INPUTS */

input[type="text"], input[type="email"], input[type="url"], input[type="tel"] {
    margin-bottom: 1rem;
    margin-top: 5px;
    height: 2rem;
    text-indent: 10px;
    border: 1px solid var(--gray-dark);
    border-radius: 5px;
    display: block;
    color: var(--gray-dark);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="tel"]:focus, textarea:focus {
    background-color: var(--orange-opacity);
}

input[type="checkbox"] {
    vertical-align: middle;
    display: inline-block;
}

label {
    display: inline-block;
}

textarea {
    margin-top: 5px;
    padding: 10px;
    height: 200px;
    border: 1px solid #444;
    border-radius: 5px;
    display: block;
    resize: vertical;
    color: var(--gray-dark);
    font-family: var(--font-stack);
    margin-bottom: 1rem;
}

::placeholder {
    font-family: 'Karla', sans-serif;
    color: var(--gray-light);
    font-size: 1rem;
}

button {
    background: none;
    border: none;
}

.success-message {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    padding: 1rem;
}




