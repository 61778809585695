.navbar {
    width: 100%;
    height: var(--height-navbar); 
    position: fixed;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: var(--section-shadow);
    font-family: 'karla', Arial, 'Roboto', sans-serif;
    z-index: 10
}

.navbar--logo {
    height: 45px;
    align-self: center;
    margin-top: 10px;
}
.footer {
    background-color: var(--gray-dark);
    color: white;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-section {
    margin-bottom: var(--element-margin);
}

.footer-title {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 0.5rem;
}

.footer-list p {
    color: var(--gray-light);
    text-align: center;
    margin: 0.25rem auto;
}

/* STORE LOGOS */

.store-logo {
    display: block;
    margin: 0 auto;
    height: 80px;
}

/* SOCIAL LOGOS */

.social-logos {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.social-logo {
    height: 40px;
}

/* COPYRIGHT */

.footer-copyright div {
    color: black;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
    font-size: 1rem;
    padding: 0;
}


@media (min-width: 768px) {
    .footer-main {
        flex-direction: row-reverse;
        grid-gap: 4rem;
        gap: 4rem;
        align-items: flex-start;
    }
}
/* GENERAL STYLING BUTTON */

.button {
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    padding: 0.8rem 2.5rem;
    margin: 2rem auto 0rem;
    box-shadow: var(--element-shadow);
    border-radius: 30px;
    text-decoration: none;
}

.button-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    text-decoration: none;
}

.button:hover, .button:focus {
    background-color: var(--gray-dark);
    color: white;
    transition: all 0.3s ease-out;
    border: 2px solid transparent;
}

/* SPECIFIC BUTTONS */

.primary {
    background-color: var(--orange-dark);
    color: white;
    border: 2px solid var(--orange-dark);
}

.secondary {
    background-color: white;
    color: var(--orange-dark);
    border: 2px solid var(--orange-dark);
}

/* DISABLED */

.cta-disabled {
    display: none;
}

/* ACTIONS */
/* -------- Action Thumb Mini -------- */

.action-thumb-mini {
    border: 2px solid;
    border-radius: 10px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    flex-shrink: 0;
    margin-right: 1rem;
}

.thumb-mini-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    height: 100%;
    font-weight: 600;
    font-size: 1rem;
}

.thumb-mini-score {
    padding-bottom: 0.5rem;
    display: flex;
}

.thumb-mini-points {
    font-weight: 600;
    align-self: flex-end;
    padding-right: 3px;
}

.thumb-mini-leaf {
    color: white
}

.contactform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: var(--element-margin) 1rem 0;
}



.leadform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: var(--element-margin) 1rem 0;
}



.about-page h1 {
    line-height: 3.5rem;
}

.about-page h3 {
    font-size: 1.2rem;
    
}

.section-divider {
    background-color: var(--orange-opacity);
}


.section-slider {
    display: flex;
    overflow-x: auto;
    padding-left: 1rem;
    margin-top: 3rem;
}

.section-slider::-webkit-scrollbar {
    display: none;
} /* to deactivate the scrollbar*/

.contact-form {
    margin: var(--element-margin) 1rem 0;
    display: flex;
}


.checkbox-acceptance {
    display: flex;
    align-items: center;
}

label {
    margin-left: 6px;
}

input[type="checkbox"] {
    width:1.5rem;
    height: 1.3rem;
}

.soon-page .contactform {
    padding-left: 0;
    padding-right: 0;

}

.subscription-form .success-message {
    margin-top: 2rem;
}

@media (min-width: 768px) {
    .soon-page .page-header {
        flex-direction: column;
    }
}


.legal-page .page-header {
    flex-direction: column;
}

.legal-page .header-bg {
    height: 100%
}
.contact-page .contactform {
    padding-left: 0;
    padding-right: 0;

}

.contact-page .header-bg {
    margin-bottom: 2rem;
}

.contact-form .success-message {
    margin-top: 2rem;
}

@media (min-width: 768px) {
    .contact-page .page-header {
        flex-direction: column;
    }

    .contact-page h1 {
        font-size: 2rem;
    }

}
.accordion-container {
    margin: 2rem 1rem 0;
    z-index: 0;
}

.MuiAccordion-root {
    background-color: white;
    border: 1px solid var(--gray-dark);
    box-shadow: none;
    margin-bottom: 1rem;
    border-radius: 5px;
}

/* General Style of the Typography */
.MuiTypography-root {
    font-family: var(--font-stack);
    font-size: 1rem;
    color: var(--gray-dark);
}

.MuiTypography-root .bold {
    font-weight: 600;
}

/* Style of the title of each accordion */
.MuiAccordionSummary-root .MuiTypography-root {
    font-weight: 600;
    font-size: 1.2rem;
}

/* Style of the content of each accordion */

.MuiAccordionDetails-root .MuiTypography-root {
    font-weight: 300;
}

li {
    margin-bottom: 0.5rem;
}
/* GENERAL CSS VARIABLES */

:root {
--orange-dark: #EA907A; /* Also to be changed in hooks/actionColor */
--orange-light: #f6d7a7; /* needed for opacity */
--green-dark: #87AAAA; /* Also to be changed in hooks/actionColor */
--green-light: #C8E3D4; /* needed for opacity */
--gray-dark: #555555; /* Also to be changed in hooks/actionColor */
--gray-light: #CBCBCB;
--blue-dark: #1572A1; /* Also to be changed in hooks/actionColor */
--blue-light: #9AD0EC;
--purple-dark: #A267AC; /* Also to be changed in hooks/actionColor */
--purple-light: #FFBCD1;
--green-opacity: rgb(200, 227, 212, 0.4); /* green-light with opacity */
--orange-opacity: rgb(246, 215, 167, 0.4); /* orange-light with opacity */
--font-stack: 'karla', Arial, 'Roboto', sans-serif;
--font-special: 'Caveat', 'cursive', sans-serif;
--height-navbar: 4rem;
--element-margin: 2rem; /* spacing between 2 elements on a screen */
--element-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* box shadow of a button or box on a screen */
--section-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* box shadow of a section on a screen */
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


/* GENERAL CONTAINERS */

body, html {
    margin: 0;
    padding: 0;
    font-family: 'karla', Arial, 'Roboto', sans-serif;
    font-family: var(--font-stack);
    color: #555555;
    color: var(--gray-dark);
    font-size: 16px;
    line-height: normal;
}

input, select, textarea { 
    font-size: 100%; 
}

/* to contain all the elements of a specific screen */
.main-container {
    padding-top: 4rem;
    padding-top: var(--height-navbar);
}


/* GENERAL STYLES */

h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    padding: 0px;
}

h1 {
    font-size: 2.5rem;
    font-weight: 400;
}

h1 > .special-font {
    font-size: 3.25rem; /* 1.3 ratio */
}

h2 {
    font-size: 2rem;
    font-weight: 300;
}

h2 > .special-font {
    font-size: 2.6rem; /* 1.3 ratio */
}

h3 {
    font-size: 1.25rem;
    font-weight: 300;
    
}

h3 > .special-font {
    font-size: 1.625rem; /* 1.3 ratio */
}

/* Fancy font caveat */
.special-font {
    font-family: 'Caveat', 'cursive', sans-serif;
    font-family: var(--font-special);
    color: #EA907A;
    color: var(--orange-dark);
}


/* to remove style from any <Link> */
.link-router {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

@media (min-width: 768px) {

    h1 .special-font {
        font-size: 3.9rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h2 .special-font {
        font-size: 3.25rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h3 .special-font {
        font-size: 1.95rem;
    }
}

@media (max-height: 700px) {
    h1 {
        font-size: 2rem;
        line-height: 2.6rem;
    }

    h1 .special-font {
        font-size: 2.6rem;
    }

    h3 {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    h3 .special-font {
        font-size: 1.3rem;
    }

    
}


/* CHEVRON DOWN */

.chevron-bottom {
    color: var(--gray-dark);
    align-self: center;
    font-size: 2em;
    text-shadow: var(--element-shadow);
    -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    z-index: -1;
}

@-webkit-keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

@keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }


/* PAGE HEADER SECTION */

.header-bg {
    padding: var(--element-margin) 1rem 4rem 1rem;
    background-color: white;
}

.page-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page-header h3 {
    padding-top: var(--element-margin);
    font-weight: 300;
    line-height: 2rem;
}

.section-image {
    display: block;
    max-height: 300px;
    max-width: 70%;
    margin: var(--element-margin) auto 0;
    object-fit: contain;
}



  /* SECTION VALUE PROP */

.section-bg {
    background-color: var(--green-opacity);
    height: 100%;
    padding: 4rem 0 4rem 0;
}

.page-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-top {
    padding: 0rem 1rem;
}

.page-section h3 {
    padding-top: var(--element-margin);
    font-weight: 300;
    line-height: 2rem;
}

.orange-bg {
    background-color: var(--orange-opacity);
}

.green-bg {
    background-color: var(--green-opacity);
}

.white-bg {
    background-color: white;
}

.section-divider-line {
    border-bottom: 1px solid var(--gray-dark);
    margin: 0 auto;
    width: 200px;
}

.section-divider {
    padding: 2rem 0 0;
}


/* MEDIA QUERIES */

@media (min-width: 768px) {

    .header-bg {
        display: flex;
        flex-direction: column;
        height: 90vh;
    }

    .page-header, .page-section {
        max-width: 800px;
        margin: auto;
    }

    .page-header {
        flex-direction: row;
    }

    .page-header h1 {
        margin-bottom: 2rem;
        font-size: 3rem;
    }

    .header-top {
        margin-right: 2rem;
    }

    .section-image {
        margin: 3rem auto 0rem;
        object-fit: contain;
    }
}

@media (max-height: 700px) {
    .section-image {
        max-height: 200px;
    }
}
/* INPUTS */

input[type="text"], input[type="email"], input[type="url"], input[type="tel"] {
    margin-bottom: 1rem;
    margin-top: 5px;
    height: 2rem;
    text-indent: 10px;
    border: 1px solid var(--gray-dark);
    border-radius: 5px;
    display: block;
    color: var(--gray-dark);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="tel"]:focus, textarea:focus {
    background-color: var(--orange-opacity);
}

input[type="checkbox"] {
    vertical-align: middle;
    display: inline-block;
}

label {
    display: inline-block;
}

textarea {
    margin-top: 5px;
    padding: 10px;
    height: 200px;
    border: 1px solid #444;
    border-radius: 5px;
    display: block;
    resize: vertical;
    color: var(--gray-dark);
    font-family: var(--font-stack);
    margin-bottom: 1rem;
}

::-webkit-input-placeholder {
    font-family: 'Karla', sans-serif;
    color: var(--gray-light);
    font-size: 1rem;
}

:-ms-input-placeholder {
    font-family: 'Karla', sans-serif;
    color: var(--gray-light);
    font-size: 1rem;
}

::placeholder {
    font-family: 'Karla', sans-serif;
    color: var(--gray-light);
    font-size: 1rem;
}

button {
    background: none;
    border: none;
}

.success-message {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    padding: 1rem;
}







