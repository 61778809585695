/* GENERAL STYLING BUTTON */

.button {
    cursor: pointer;
    width: fit-content;
    display: flex;
    justify-content: center;
    padding: 0.8rem 2.5rem;
    margin: 2rem auto 0rem;
    box-shadow: var(--element-shadow);
    border-radius: 30px;
    text-decoration: none;
}

.button-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    text-decoration: none;
}

.button:hover, .button:focus {
    background-color: var(--gray-dark);
    color: white;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    border: 2px solid transparent;
}

/* SPECIFIC BUTTONS */

.primary {
    background-color: var(--orange-dark);
    color: white;
    border: 2px solid var(--orange-dark);
}

.secondary {
    background-color: white;
    color: var(--orange-dark);
    border: 2px solid var(--orange-dark);
}

/* DISABLED */

.cta-disabled {
    display: none;
}
