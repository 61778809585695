/* PAGE HEADER SECTION */

.header-bg {
    padding: var(--element-margin) 1rem 4rem 1rem;
    background-color: white;
}

.page-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page-header h3 {
    padding-top: var(--element-margin);
    font-weight: 300;
    line-height: 2rem;
}

.section-image {
    display: block;
    max-height: 300px;
    max-width: 70%;
    margin: var(--element-margin) auto 0;
    object-fit: contain;
}



  /* SECTION VALUE PROP */

.section-bg {
    background-color: var(--green-opacity);
    height: 100%;
    padding: 4rem 0 4rem 0;
}

.page-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-top {
    padding: 0rem 1rem;
}

.page-section h3 {
    padding-top: var(--element-margin);
    font-weight: 300;
    line-height: 2rem;
}

.orange-bg {
    background-color: var(--orange-opacity);
}

.green-bg {
    background-color: var(--green-opacity);
}

.white-bg {
    background-color: white;
}

.section-divider-line {
    border-bottom: 1px solid var(--gray-dark);
    margin: 0 auto;
    width: 200px;
}

.section-divider {
    padding: 2rem 0 0;
}


/* MEDIA QUERIES */

@media (min-width: 768px) {

    .header-bg {
        display: flex;
        flex-direction: column;
        height: 90vh;
    }

    .page-header, .page-section {
        max-width: 800px;
        margin: auto;
    }

    .page-header {
        flex-direction: row;
    }

    .page-header h1 {
        margin-bottom: 2rem;
        font-size: 3rem;
    }

    .header-top {
        margin-right: 2rem;
    }

    .section-image {
        margin: 3rem auto 0rem;
        object-fit: contain;
    }
}

@media (max-height: 700px) {
    .section-image {
        max-height: 200px;
    }
}