.about-page h1 {
    line-height: 3.5rem;
}

.about-page h3 {
    font-size: 1.2rem;
    
}

.section-divider {
    background-color: var(--orange-opacity);
}