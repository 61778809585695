.navbar {
    width: 100%;
    height: var(--height-navbar); 
    position: fixed;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: var(--section-shadow);
    font-family: 'karla', Arial, 'Roboto', sans-serif;
    z-index: 10
}

.navbar--logo {
    height: 45px;
    align-self: center;
    margin-top: 10px;
}