.accordion-container {
    margin: 2rem 1rem 0;
    z-index: 0;
}

.MuiAccordion-root {
    background-color: white;
    border: 1px solid var(--gray-dark);
    box-shadow: none;
    margin-bottom: 1rem;
    border-radius: 5px;
}

/* General Style of the Typography */
.MuiTypography-root {
    font-family: var(--font-stack);
    font-size: 1rem;
    color: var(--gray-dark);
}

.MuiTypography-root .bold {
    font-weight: 600;
}

/* Style of the title of each accordion */
.MuiAccordionSummary-root .MuiTypography-root {
    font-weight: 600;
    font-size: 1.2rem;
}

/* Style of the content of each accordion */

.MuiAccordionDetails-root .MuiTypography-root {
    font-weight: 300;
}

li {
    margin-bottom: 0.5rem;
}