
/* CHEVRON DOWN */

.chevron-bottom {
    color: var(--gray-dark);
    align-self: center;
    font-size: 2em;
    text-shadow: var(--element-shadow);
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    animation-delay: 3s;
    z-index: -1;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

