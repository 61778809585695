.contact-page .contactform {
    padding-left: 0;
    padding-right: 0;

}

.contact-page .header-bg {
    margin-bottom: 2rem;
}

.contact-form .success-message {
    margin-top: 2rem;
}

@media (min-width: 768px) {
    .contact-page .page-header {
        flex-direction: column;
    }

    .contact-page h1 {
        font-size: 2rem;
    }

}