/* GENERAL CSS VARIABLES */

:root {
--orange-dark: #EA907A; /* Also to be changed in hooks/actionColor */
--orange-light: #f6d7a7; /* needed for opacity */
--green-dark: #87AAAA; /* Also to be changed in hooks/actionColor */
--green-light: #C8E3D4; /* needed for opacity */
--gray-dark: #555555; /* Also to be changed in hooks/actionColor */
--gray-light: #CBCBCB;
--blue-dark: #1572A1; /* Also to be changed in hooks/actionColor */
--blue-light: #9AD0EC;
--purple-dark: #A267AC; /* Also to be changed in hooks/actionColor */
--purple-light: #FFBCD1;
--green-opacity: rgb(200, 227, 212, 0.4); /* green-light with opacity */
--orange-opacity: rgb(246, 215, 167, 0.4); /* orange-light with opacity */
--font-stack: 'karla', Arial, 'Roboto', sans-serif;
--font-special: 'Caveat', 'cursive', sans-serif;
--height-navbar: 4rem;
--element-margin: 2rem; /* spacing between 2 elements on a screen */
--element-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* box shadow of a button or box on a screen */
--section-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* box shadow of a section on a screen */
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


/* GENERAL CONTAINERS */

body, html {
    margin: 0;
    padding: 0;
    font-family: var(--font-stack);
    color: var(--gray-dark);
    font-size: 16px;
    line-height: normal;
}

input, select, textarea { 
    font-size: 100%; 
}

/* to contain all the elements of a specific screen */
.main-container {
    padding-top: var(--height-navbar);
}


/* GENERAL STYLES */

h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    padding: 0px;
}

h1 {
    font-size: 2.5rem;
    font-weight: 400;
}

h1 > .special-font {
    font-size: 3.25rem; /* 1.3 ratio */
}

h2 {
    font-size: 2rem;
    font-weight: 300;
}

h2 > .special-font {
    font-size: 2.6rem; /* 1.3 ratio */
}

h3 {
    font-size: 1.25rem;
    font-weight: 300;
    
}

h3 > .special-font {
    font-size: 1.625rem; /* 1.3 ratio */
}

/* Fancy font caveat */
.special-font {
    font-family: var(--font-special);
    color: var(--orange-dark);
}


/* to remove style from any <Link> */
.link-router {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

@media (min-width: 768px) {

    h1 .special-font {
        font-size: 3.9rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h2 .special-font {
        font-size: 3.25rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h3 .special-font {
        font-size: 1.95rem;
    }
}

@media (max-height: 700px) {
    h1 {
        font-size: 2rem;
        line-height: 2.6rem;
    }

    h1 .special-font {
        font-size: 2.6rem;
    }

    h3 {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    h3 .special-font {
        font-size: 1.3rem;
    }

    
}
