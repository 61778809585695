/* ACTIONS */
/* -------- Action Thumb Mini -------- */

.action-thumb-mini {
    border: 2px solid;
    border-radius: 10px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    flex-shrink: 0;
    margin-right: 1rem;
}

.thumb-mini-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    height: 100%;
    font-weight: 600;
    font-size: 1rem;
}

.thumb-mini-score {
    padding-bottom: 0.5rem;
    display: flex;
}

.thumb-mini-points {
    font-weight: 600;
    align-self: flex-end;
    padding-right: 3px;
}

.thumb-mini-leaf {
    color: white
}
